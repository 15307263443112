import React from "react";
import { Artigo } from "../../components";
import "./style.css";

export class ArtigoScreen extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Artigo source={this.props.artigo} />
      </React.Fragment>
    );
  }
}
