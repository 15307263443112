import React from "react";
import { Button, CardServicos } from "../../components";
import email_icon from "../../img/email_icon.png";
import telefone_icon from "../../img/telefone_icon.png";
import adress_icon from "../../img/adress_icon.png";
import GoogleMapReact from "google-map-react";
import "./style.css";

export class HomeScreen extends React.Component {
  constructor() {
    super();

    this.state = {
      posicaoScroll: undefined,
      mostrarHeader: true,
    };

    this.center = {
      lat: -29.77693,
      lng: -51.153021,
    };

    this.listaServicos = [
      {
        titulo: "Palestras Semanais",
        link: "/palestras",
        backgroundImage:
          "https://user-images.githubusercontent.com/30511610/80318288-8827fc00-87df-11ea-8bf0-85fb604c8066.jpg",
      },
      {
        titulo: "Grupos de Estudo",
        link: "/grupo-estudo",
        backgroundImage:
          "https://user-images.githubusercontent.com/30511610/80318513-1d77c000-87e1-11ea-895d-780a2b70d3bc.jpg",
      },
      {
        titulo: "Evangelização",
        link: "/evangelizacao",
        backgroundImage:
          "https://user-images.githubusercontent.com/30511610/80319564-5e72d300-87e7-11ea-8a56-eecca0168062.jpg",
      },
      {
        titulo: "Atendimento Fraterno",
        link: "/atendimento-fraterno",
        backgroundImage:
          "https://user-images.githubusercontent.com/30511610/80319480-c8d74380-87e6-11ea-94e5-1f33943b6e37.jpg",
      },
    ];
  }

  renderSectionBanner = () => {
    return (
      <React.Fragment>
        <section className="home-container-banner">
          <div className="home-banner-cortina">
            <div className="home-banner-nuvem"></div>
            {this.renderTituloBanner()}
            {this.renderConteudoBanner()}
          </div>
        </section>
      </React.Fragment>
    );
  };

  renderTituloBanner = () => {
    return (
      <div className="home-banner-titulo">
        <div className="home-banner-titulo-menor">Sociedade Espírita</div>
        <div className="home-banner-titulo-maior">Luz, Fé e Caridade</div>
      </div>
    );
  };
  renderConteudoBanner = () => {
    return (
      <React.Fragment>
        <div className="home-banner-container-conteudo">
          <p>
            A Sociedade Espírita Luz, Fé e Caridade foi fundada em 1 de
            novembro de 1936, na cidade de São Leopoldo, sendo a casa espírita
            mais antiga da cidade. A entidade é vinculada à Federação Espírita
            do Rio Grande do Sul (FERGS) e à União Municipal Espírita de São
            Leopoldo (UME-SL). Seus maiores objetivos são divulgar a Doutrina
            Espírita e levar orientação e consolo espiritual às pessoas.
          </p>
          <Button
            redirect="/sobre"
            class={"button-modelo-banner"}
            conteudo="Saiba Mais >"
          />
        </div>
      </React.Fragment>
    );
  };

  renderSectionServicos = () => {
    return (
      <section className="home-container-section">
        <div className="home-container-servicos">
          {this.renderCardServicos()}
        </div>
      </section>
    );
  };

  renderCardServicos = () => {
    return this.listaServicos.map((element, key) => {
      return (
        <CardServicos
          titulo={element.titulo}
          redirect={element.link}
          backgroundImage={element.backgroundImage}
          key={key}
        />
      );
    });
  };

  renderSectionAtividades = () => {
    return (
      <section className="home-container-section">
        <div className="home-container-conteudo-servicos">
          <div className="home-container-conteudo-texto">
            <span>Nossas Atividades</span>
            <p>
              O Centro Espírita é um local que se caracteriza por aproximar
              pessoas que, em comum, buscam a luz do espiritismo como elemento
              transformador de suas vidas. Tem como objetivo divulgar, estudar e
              incentivar a pratica desta doutrina, cujos postulados estão
              ancorados no evangelho de Jesus. Para tanto, recebe e acolhe a
              todos que buscam esclarecimento, consolo, orientação e amparo para
              suas dificuldades de ordem espiritual, moral e material. Também
              incentiva o estudo para adultos, jovens e crianças que querem
              conhecer a Doutrina, assim como possibilita para aqueles que
              querem servir no bem a oportunidade de colaborar nas diversas
              atividades que a prática espírita oferece.
            </p>
          </div>
          <img
            src="https://user-images.githubusercontent.com/30511610/87201387-d7f16c00-c2d4-11ea-9d34-763fd917b59d.jpg"
            alt="Jesus Christ"
          ></img>
        </div>
      </section>
    );
  };

  renderSectionContato = () => {
    return (
      <section className="home-container-section-azul">
        <div className="home-titulo-section">Onde nos encontrar</div>
        <div className="home-container-contato">
          {this.renderInfosContato()}
          {this.renderGoogleMaps()}
        </div>
      </section>
    );
  };

  renderGoogleMaps = () => {
    return (
      <div className="home-container-maps">
        <div style={{ height: "100%", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDbbuCbZhK4fL3X24v0J7zqBSJqAMmpDUU",
            }}
            defaultCenter={this.center}
            defaultZoom={15}
            onGoogleApiLoaded={({ map, maps }) => this.renderMarker(map, maps)}
          ></GoogleMapReact>
        </div>
      </div>
    );
  };

  renderMarker = (map, maps) => {
    new maps.Marker({
      position: this.center,
      map,
      title: "Sociedade Luz, Fé e Caridade",
    });
  };

  renderInfosContato = () => {
    return (
      <div className="home-container-contato-infos" id="contato">
        <div className="home-container-info">
          <img src={adress_icon} width="30px" alt="adress_icon"></img>
          <span>R. Bela Vista, 89 - Fião, São Leopoldo - RS, 93020-520</span>
        </div>
        <div className="home-container-info">
          <img src={telefone_icon} width="30px" alt="telefone_icon"></img>
          <span>+55 (51) 99174-4789 (Helga)</span>
        </div>
        <div className="home-container-info">
          <img src={email_icon} width="30px" alt="email_icon"></img>
          <span>selfc1857@gmail.com</span>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderSectionBanner()}
        {this.renderSectionAtividades()}
        {this.renderSectionServicos()}
        {this.renderSectionContato()}
      </React.Fragment>
    );
  }
}
