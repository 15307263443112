import React from "react";
import { Route } from "react-router-dom";
import "./App.css";
import {
  HomeScreen,
  SobreScreen,
  ArtigoScreen,
} from "./screens";
import ArtigoEspiritismo from "./storage/artigo-espiritismo.md";
import AtendimentoFraterno from "./storage/atendimento-fraterno.md";
import Evangelizacao from "./storage/evangelizacao.md";
import GrupoEstudo from "./storage/grupo-estudo.md";
import Palestras from "./storage/palestras.md";

function App() {
  return (
    <React.Fragment>
      <Route path="/" component={HomeScreen} exact />
      <Route path="/sobre" component={SobreScreen} exact />
      <Route
        path="/espiritismo"
        component={() => <ArtigoScreen artigo={ArtigoEspiritismo} />}
        exact
      />
      <Route
        path="/palestras"
        component={() => <ArtigoScreen artigo={Palestras} />}
        exact
      />
      <Route
        path="/grupo-estudo"
        component={() => <ArtigoScreen artigo={GrupoEstudo} />}
        exact
      />
      <Route
        path="/atendimento-fraterno"
        component={() => <ArtigoScreen artigo={AtendimentoFraterno} />}
        exact
      />
      <Route
        path="/evangelizacao"
        component={() => <ArtigoScreen artigo={Evangelizacao} />}
        exact
      />
    </React.Fragment>
  );
}

export default App;
