import React from "react";
import "./style.css";

export class SobreScreen extends React.Component {
  renderConteudo = () => {
    return (
      <React.Fragment>
        <div className="sobre-container-section">
          <div className="sobre-container-banner">
            <div className="sobre-banner-nuvem"></div>
            {this.renderContainerTitulo()}
          </div>
          <div className="sobre-container-conteudo">
            <div className="sobre-container-titulo-selfc">
              Sociedade Espírita Luz, Fé e Caridade
            </div>
            {this.renderConteudoHistoria()}
          </div>
        </div>
        <div className="sobre-container-section-b">
          <div className="sobre-container-missao">
            {this.renderConteudoMissao()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderConteudoMissao = () => {
    return (
      <React.Fragment>
        <div className="sobre-container-missao-titulo">
          <div className="sobre-container-missao-icone"></div>
          <span>Nossa Missão</span>
        </div>
        <div className="sobre-container-missao-descricao">
          <p>
            A missão da Sociedade Espírita Luz, Fé e Caridade consiste no
            estudo, difusão e prática da Doutrina Espírita, codificada por Allan
            Kardec, primando pela orientação à infância, juventude e família,
            garantindo assistência e promoção social, de forma a servir, acima
            de tudo, como centro de consolação espiritual aos que a procura.
          </p>
        </div>
      </React.Fragment>
    );
  };
  renderContainerTitulo = () => {
    return (
      <div className="sobre-container-titulo">
        <span>Sobre nós</span>
        <p>
          Conheça um pouco mais da história da casa espírita mais antiga de São
          Leopoldo, que, desde 1936, trabalha na divulgação e prática da
          Doutrina Espírita.
        </p>
      </div>
    );
  };

  renderConteudoHistoria = () => {
    return (
      <React.Fragment>
        <div className="container-conteudo-historia">
          <div className="sobre-container-conteudo-texto">
            <p>
              A Sociedade Espírita Luz, Fé e Caridade foi fundada em 1 de
              novembro de 1936, é a entidade espírita federada mais antiga de
              São Leopoldo, tendo como patrono espiritual Dias da Cruz. Seu
              objetivo está na divulgação da Doutrina Espírita, codificada por
              Allan Kardec, para que ela possa servir de consolação espiritual
              aos que a procura em sua sede física. Este site também tem o mesmo
              objetivo, no ambiente virtual.
            </p>
            <p>
              Nossa história começa em meados da década de 1930, no bairro Fião,
              na cidade de São Leopoldo, período em que o Sr. Lima atendia em
              sua casa diversas pessoas em situação de sofrimento. Ele
              ministrava passes e ofertava boas palavras a todos que a ele
              recorriam. O número de pessoas que necessitavam de acolhimento
              espiritual começou a crescer e, por isso, o irmão Lima, juntamente
              com alguns companheiros, formaram, em 1936, o Grupo Espírita Luz,
              Fé e Caridade, no mesmo bairro, mas ainda não na localidade atual.
            </p>
            <p>
              O irmão Lima, um senhor simples, não era alfabetizado, mas o fato
              de não saber ler, não o impedia de difundir a Doutrina Espírita. A
              esposa dele lia o Evangelho Segundo o Espiritismo para ele, e ele,
              posteriormente, fazia belas exposições para o público.
            </p>
            <p>
              Oito anos mais tarde, em 1944, o grupo conquistou seu espaço na
              Rua Bela Vista, nº 89, no mesmo Bairro, onde até hoje são
              realizadas as atividades. O irmão Lima permaneceu à frente do
              grupo durante dez anos, até desencarnar.
            </p>
          </div>
          <div className="container-conteudo-historia-imagens"></div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderConteudo()}
      </React.Fragment>
    );
  }
}
