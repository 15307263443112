import React from "react";
import { Link as ReactLink } from "react-router-dom";
import "./style.css";
import facebook_logo from "../../img/facebook.png";
import instagram_logo from "../../img/instagram.png";
import logo from "../../img/logo.png"

export class Header extends React.Component {
  constructor() {
    super();

    this.state = {
      posicaoScroll: undefined,
      mostrarHeader: true,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.setPosicaoScrool);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.setPosicaoScrool);
  }

  setPosicaoScrool = () => {
    let scrollAtual = window.pageYOffset;
    this.setState({ mostrarHeader: this.state.posicaoScroll > scrollAtual });
    this.setState({ posicaoScroll: scrollAtual });
  };

  renderLinks() {
    return (
      <React.Fragment>
        <nav className="header-menu">
          <input type="checkbox" id="menu-control" />
          <ul className="header-links">
            <li>
              <ReactLink to="/">Home</ReactLink>
            </li>
            <li>
              <ReactLink to="/espiritismo">O Espiritismo</ReactLink>
            </li>
            <li>
              <ReactLink to="/sobre">Sobre nós</ReactLink>
            </li>
          </ul>
          <label htmlFor="menu-control" className="hamburguer"></label>
        </nav>
      </React.Fragment>
    );
  }

  renderRedeSocial = () => {
    return (
      <div className="header-container-social">
        <a href="https://www.facebook.com/selfcsaoleopoldo/" target="_blank" rel="noopener noreferrer">
          <img src={facebook_logo} width="30px" alt="facebook_icon.png"></img>
        </a>
        <a href="https://www.instagram.com/selfcsaoleopoldo/" target="_blank" rel="noopener noreferrer">
          <img src={instagram_logo} width="30px" alt="facebook_icon.png"></img>
        </a>
      </div>
    );
  };

  renderConteudoHeader = () => {
    return (
      <React.Fragment>
        <img src={ logo } alt="Logo da SELFC" />
        <div className="header-container-links">
          {this.renderLinks()}
          {this.renderRedeSocial()}
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="header"
          style={{ height: this.state.mostrarHeader ? "70px" : "0px" }}
        >
          {this.state.mostrarHeader && this.renderConteudoHeader()}
        </div>
      </React.Fragment>
    );
  }
}
