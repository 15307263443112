import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import './style.css';

export class CardServicos extends React.Component {
	render() {
		return (
			<Link to={this.props.redirect} className='container-card-servicos' 
			style={{backgroundImage: `url(${this.props.backgroundImage})`}}>
				<div className='container-cortina-card-servico'>
                	<span>{this.props.titulo}</span>
				</div>
            </Link>
		);
	} 
}
