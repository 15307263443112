import React from "react";
import "./style.css";

import ReactMarkdown from "react-markdown";

export class Artigo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markdown: null,
    };

    fetch(this.props.source)
      .then((response) => response.text())
      .then((text) => {
        this.setState({ markdown: text });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="artigo-container-section"
          id={this.props.id}
          href={this.props.href}
        >
          <div className="artigo-container-conteudo">
            <ReactMarkdown source={this.state.markdown} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
