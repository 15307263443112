import React from "react";
import "./style.css";

export class Footer extends React.Component {

  renderConteudoFooter = () => {
    return (
      <React.Fragment>
        <div className="footer-container-central">
          <div>S. E Luz, Fé e Caridade</div>
          <div className="footer-copyright">
            Copyright 2019 Todos os direitos reservados à S. E Luz, Fé e
            Caridade.
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="footer">{this.renderConteudoFooter()}</div>
      </React.Fragment>
    );
  }
}
