import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

export class Button extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Link to={this.props.redirect} className={this.props.class} onClick={this.props.onClick}>
					{this.props.conteudo}
				</Link>
			</React.Fragment>
		);
	}
}
